export default {
    translation: {
        main: {
            title: 'Super simple personal budget management app',
            text: 'The interface of many budget management apps is outdated, and their functionality is overloaded.',
            boldText: 'We\'ve made it simpler.',
        },
        features: {
            shortFeature1: 'No registration',
            shortFeature2: 'No data collection',
            shortFeature3: 'Fully offline',
            siriTitle: 'Track your spendings by voice command with Siri and Shortcuts',
            siriText: `“Hey Siri,\nTrack my spending”`,
            widgetsTitle: 'Add widgets to home or lock screen to keep your numbers always in sight',
            smartSuggestsTitle: 'Smart suggestions for one-tap spending tracking',
            smartSuggestsText: 'Moneeey will show suggests for your repeated spendings, or for one you\'ve tracked earlier in your current location.',
            smartSuggestsTip: 'For this feature you must allow precise location access for the app. We do not collect your location data.',
            longFeature1: 'Set up colors of your groups for better perception, or use them as extra categories',
            longFeature2: 'Achieve your financial goals with monthly and weekly customizable budgets',
            longFeature3: 'Simple and informative statistics with two different chart views',
            longFeature4: 'Time is money. We\'ll help keep both. Add spendings with a few taps in a few seconds.',
        },
        faq: {
            title: 'App guide',
            watch_all: 'Watch full video',
            step_addFirstSpending: 'Add your first spending',
            step_addMoreGroups: 'Setup groups',
            step_sortGroups: 'Reorder your groups',
            step_addSpendings: 'Add more spendings',
            step_timeline: 'Timeline screen',
            step_editSpending: 'Edit spending',
            step_changeMonth: 'View previous months',
            step_statsForMonth: 'Statistics screen',
            step_statsForGroup: 'Statistics for group',
            step_createBudget: 'Create budget',
            step_editBudget: 'Change budget limit or name',
            step_widgets: 'Add widgets on home screen and lock screen',
            pic_suggestsLabel: 'Location suggests',
            pic_suggestsText: `1. Enable "Location suggests" option at group settings.\n\n2. Moneeey will remember places where you track spendings for this group, and will suggest this group next time.`,
        },
    }
};
