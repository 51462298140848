export default {
    translation: {
        main: {
            title: 'Супер простий додаток для вашого бюджету',
            text: 'The interface of many budget management apps is outdated, and their functionality is overloaded.',
            text: 'Більшість додатків для власних фінансів виглядають застаріло та перевантажені зайвими функціями.',
            boldText: 'Ми зробили простіше.',
        },
        features: {
            shortFeature1: 'Без реєстрації',
            shortFeature2: 'Без збору даних',
            shortFeature3: 'Повністю оффлайн',
            siriTitle: 'Додавайте витрати голосом завдяки підтримці Siri та Shortcuts',
            siriText: `“Hey Siri,\nTrack my spending”`,
            widgetsTitle: 'Актуальний стан ваших фінансів завжди на виду завдяки віджетам',
            smartSuggestsTitle: 'Розумні підказки — додавайте витрати в один дотик',
            smartSuggestsText: 'Moneeey запропонує розумні підказки для ваших регулярних витрат, або на основі поточної локації',
            smartSuggestsTip: 'Для підказок за локацією потрібно надати доступ до точної локації. Ми не збираємо жодних даних.',
            longFeature1: '12 кольорів для кращого сприйняття, або як додаткова категорія груп',
            longFeature2: 'Встановіть ліміти для своїх витрат за допомогою бюджетів',
            longFeature3: 'Проста та інформативна статистика з двома зручними режимами',
            longFeature4: 'Час — гроші. Ми збережемо їх обох. Додавайте витрати за 4 секунди і 5 дотиків.',
        },
        faq: {
            title: 'Допомога',
            watch_all: 'Дивитись повністю',
            step_addFirstSpending: 'Додати вашу першу витрату',
            step_addMoreGroups: 'Налаштувати групи',
            step_sortGroups: 'Змінити порядок груп',
            step_addSpendings: 'Додати витрати',
            step_timeline: 'Екран таймлайну',
            step_editSpending: 'Редагувати запис',
            step_changeMonth: 'Переглянути минулі місяці',
            step_statsForMonth: 'Екран статистики',
            step_statsForGroup: 'Статистика окремої групи',
            step_createBudget: 'Створити бюджет',
            step_editBudget: 'Редагувати бюджет',
            step_widgets: 'Додати віджети на домашній екран або екран блокування',
            pic_suggestsLabel: 'Підказки за локацією',
            pic_suggestsText: `1. Увімкніть опцію "Підказки за локацією" в налаштуваннях групи.\n\n2. Moneeey запамʼятає місця в яких ви використовуєте цю группу, і запропонує підказку наступного разу.`,
        },
    },
};
